<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text"><router-link to="/">Pro Track</router-link> / Add Department</h1>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 grey--text"><b>Department</b></p>
            <v-text-field
              label="Eg. Accounts"
              single-line
              outlined
              v-model="department"
            ></v-text-field>
            <v-checkbox
              v-model="addParent"
              label="Add this department under another department"
            ></v-checkbox>
            <div v-if="addParent" >
              <p class="mb-1 grey--text"><b>Add Under</b></p>
              <v-select single-line outlined :items="departments" item-text="name" label="Department" v-model="parentDepartment" item-value="_id"></v-select>
            </div>
            
          </v-flex>
          <v-flex xs12 class="pa-3">
            <v-btn x-large class="primary black--text" @click="createRole"
              >Submit</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import { BASE_URL } from "@/config";
import Axios from "axios";

export default {
  data() {
    return {
      department: "",
      departments : [],
      addParent : false,
      parentDepartment : null
    };
  },
  mounted(){this.fetchDepartments()},
  methods: {
    async fetchDepartments(){
      let url = BASE_URL + "/department"
      let {data} = await Axios.get(url)
      this.departments = data
    },
    async createRole() {
      let url = BASE_URL + "/department";
      let payload = {
        department: this.department,
      };
      if(this.addParent && this.parentDepartment){
        payload.under = this.parentDepartment
      }
      let { data } = await Axios.post(url, payload);
      this.$router.push("/departments");
      this.$toastr.s(
        "New department has been created successfully!",
        "Department Added"
      );
    },
  },
};
</script>